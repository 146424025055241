import Button from '@lyra/core/components/Button'
import filterNulls from '@lyra/core/utils/filterNulls'
import { mergeDeep } from '@lyra/core/utils/mergeDeep'
import { ArrowDown, ArrowUp, ChevronLeft, ChevronRight } from '@tamagui/lucide-icons'
import { ChevronsLeft, ChevronsRight } from '@tamagui/lucide-icons'
import { GestureReponderEvent } from '@tamagui/web'
import {
  Cell,
  CellContext,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  HeaderContext,
  OnChangeFn,
  Row,
  RowData,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import React, { isValidElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { StackProps, XStack, YStack } from 'tamagui'

export type { ColumnDef } from '@tanstack/react-table'
export type { CellContext } from '@tanstack/react-table'
import { createColumnHelper as _createColumnHelper } from '@tanstack/react-table'

import BodyText from '../BodyText'
import Icon from '../Icon'
import LabelText from '../LabelText'

declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends RowData, TValue> {
    showRightBorder?: boolean
    showLeftBorder?: boolean
    maxWidth?: number
    minWidth?: number
    justifyContent?: StackProps['justifyContent']
    noPadding?: boolean
    isDisabled?: boolean
    isSortable?: boolean
  }
}

export type TableRecordType = Record<string, any>

export type TableMarkerRow = {
  id: string | number
  insertIndex: number
  content: React.ReactNode
  autoCenter?: boolean
}

export type TableData<T extends TableRecordType> = T & {
  id?: string
  onPress?: (e: GestureReponderEvent, context: CellContext<TableData<T>, any>) => void
  isSelected?: boolean
  getIsSelected?: (props: CellContext<TableData<T>, any>) => boolean
  hoverBg?: string
  pressBg?: string
  stackProps?: StackProps
}

export type TableCellProps<T extends TableRecordType> = CellContext<TableData<T>, any> & {
  isRowHovering: boolean
  isRowPressing: boolean
  isRowSelected: boolean
}

export type TableSize = 'sm' | 'md' | 'lg'

export type TableProps<T extends TableRecordType> = {
  data: Array<TableData<T>>
  columns: Array<TableColumnDef<T>>
  pageSize?: number
  page?: number
  onChangePage?: (page: number) => void
  size?: TableSize
  markerRow?: TableMarkerRow | null
  emptyMessage?: React.ReactNode
  horizontalCenter?: boolean
  sorting?: SortingState
  onSortingChange?: OnChangeFn<SortingState>
} & StackProps

export type TableElement<T extends TableRecordType> = React.ReactElement<TableProps<T>>

export type TableColumnDef<T extends TableRecordType> = Omit<ColumnDef<T, any>, 'cell'> & {
  cell: (props: TableCellProps<T>) => React.ReactNode
}

export type TableGroupColumnDef<T extends TableRecordType> = Omit<TableColumnDef<T>, 'cell'> & {
  columns: TableColumnDef<T>[]
}

export type TableColumnHelper<T extends TableRecordType> = {
  accessor: (accessor: string, column: TableColumnDef<T>) => TableColumnDef<T>
  group: (column: TableGroupColumnDef<T>) => TableColumnDef<T>
}

export type TableCellWidthProps = {
  maxWidth?: number
  minWidth?: number
}

export const createColumnHelper = <T extends TableRecordType>(): TableColumnHelper<T> => {
  return _createColumnHelper<T>() as unknown as TableColumnHelper<T>
}

const getVerticalPadding = (size: TableSize): number => {
  switch (size) {
    case 'sm':
      return 3
    case 'md':
      return 9
    case 'lg':
      return 12
  }
}

const getHorizontalPadding = (size: TableSize): number => {
  switch (size) {
    case 'sm':
      return 6
    case 'md':
    case 'lg':
      return 9
  }
}

export const getHeaderPaddingProps = (
  context: HeaderContext<TableData<any>, unknown>,
  size: TableSize
) => {
  const { header } = context

  const headerGroup = context.header.headerGroup
  const headerIdx = headerGroup.headers.indexOf(header)

  const isFirst = headerIdx === 0
  const isLast = headerIdx === headerGroup.headers.length - 1

  return {
    paddingLeft: isFirst ? 0 : getHorizontalPadding(size),
    marginLeft: isFirst ? 12 : 0,
    paddingRight: isLast ? 0 : getHorizontalPadding(size),
    marginRight: isLast ? 12 : 0,
    paddingVertical: getVerticalPadding(size),
  }
}

const _getCellPaddingProps = (isFirst: boolean, isLast: boolean, size: TableSize) => {
  return {
    paddingLeft: isFirst ? 12 : getHorizontalPadding(size),
    paddingRight: isLast ? 12 : getHorizontalPadding(size),
    paddingVertical: getVerticalPadding(size),
  }
}

export const getCellPaddingProps = (
  context: CellContext<TableData<any>, unknown>,
  size: TableSize
) => {
  const { cell, row } = context
  const cellIdx = row.getVisibleCells().indexOf(cell)

  const isFirst = cellIdx === 0
  const isLast = cellIdx === row.getVisibleCells().length - 1

  return _getCellPaddingProps(isFirst, isLast, size)
}

export const getCellWidthProps = (
  widthProps: TableCellWidthProps,
  paddingProps: ReturnType<typeof getCellPaddingProps> | ReturnType<typeof getHeaderPaddingProps>
) => {
  const { minWidth, maxWidth } = widthProps
  const horizontalPadding = paddingProps['paddingLeft'] + paddingProps['paddingRight']
  return {
    minWidth: minWidth ? minWidth + horizontalPadding : undefined,
    maxWidth: maxWidth ? maxWidth + horizontalPadding : undefined,
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 0,
  }
}

const EmptyTableBodyRow = ({
  size,
  emptyMessage,
}: {
  size: TableSize
  emptyMessage: React.ReactNode
}) => {
  const paddingProps = _getCellPaddingProps(true, true, size)
  const widthProps = getCellWidthProps({}, paddingProps)

  // Note: we always need to use paddingProps to derive widthProps, even when noPadding is set
  const styleProps: StackProps = mergeDeep(paddingProps, widthProps)

  return <XStack {...styleProps}>{emptyMessage}</XStack>
}

const TableBodyRow = <T extends TableRecordType>({
  row,
  size,
}: {
  row: Row<TableData<T>>
  size: TableSize
}) => {
  const [hoverColumnGroup, setHoverColumnGroup] = useState<string | boolean>(false)
  const [pressedColumnGroup, setPressedColumnGroup] = useState<string | boolean>(false)

  return (
    <XStack {...row.original.stackProps}>
      {row.getVisibleCells().map((cell: Cell<TableData<T>, any>) => {
        const columnGroupId = cell.column.parent?.id

        const { minWidth, maxWidth, justifyContent, isDisabled, showRightBorder, showLeftBorder } =
          cell.column.columnDef.meta ?? {}

        const isClickable = !!row.original.onPress && !isDisabled

        const renderCell = cell.column.columnDef.cell

        const paddingProps = getCellPaddingProps(cell.getContext(), size)
        const widthProps = getCellWidthProps({ minWidth, maxWidth }, paddingProps)

        // Note: we always need to use paddingProps to derive widthProps, even when noPadding is set
        const styleProps: StackProps = mergeDeep(paddingProps, widthProps)

        const props = cell.getContext()

        const isRowSelected =
          typeof row.original.isSelected === 'boolean'
            ? row.original.isSelected
            : row.original.getIsSelected
            ? row.original.getIsSelected(props)
            : false

        const isRowHovering =
          (typeof hoverColumnGroup === 'string'
            ? columnGroupId === hoverColumnGroup
            : hoverColumnGroup) && isClickable
        const isRowPressing =
          (typeof pressedColumnGroup === 'string'
            ? columnGroupId === pressedColumnGroup
            : pressedColumnGroup) && isClickable

        const { onPress, pressBg, hoverBg } = row.original

        const cellProps: TableCellProps<T> = {
          ...props,
          isRowHovering,
          isRowSelected,
          isRowPressing,
        }

        const cellElement: React.ReactElement =
          typeof renderCell === 'function' ? renderCell(cellProps) : renderCell

        return (
          <XStack
            {...styleProps}
            minHeight={size === 'md' ? '$md' : size === 'lg' ? '$lg' : undefined}
            key={cell.id}
            onHoverIn={
              isClickable ? () => setHoverColumnGroup(cell.column.parent?.id ?? true) : undefined
            }
            onHoverOut={isClickable ? () => setHoverColumnGroup(false) : undefined}
            onPressIn={
              isClickable ? () => setPressedColumnGroup(cell.column.parent?.id ?? true) : undefined
            }
            onPressOut={isClickable ? () => setPressedColumnGroup(false) : undefined}
            onPress={(e) => {
              if (isDisabled) {
                return
              }
              if (onPress) {
                onPress(e, props)
              }
            }}
            borderRadius="$1"
            borderColor="$hairline"
            borderLeftWidth={showLeftBorder ? '1px' : undefined}
            borderRightWidth={showRightBorder ? '1px' : undefined}
            backgroundColor={
              isRowPressing && isClickable
                ? pressBg ?? '$pressBg'
                : (isRowHovering && isClickable) || isRowSelected
                ? hoverBg ?? '$hoverBg'
                : undefined
            }
            cursor={isClickable ? 'pointer' : 'inherit'}
            justifyContent={justifyContent}
            alignItems="center"
          >
            {cellElement}
          </XStack>
        )
      })}
    </XStack>
  )
}

const cleanPage = (page: number, numPages: number): number =>
  Math.min(Math.max(0, page), numPages - 1)

const Table = <T extends TableRecordType>({
  columns,
  data,
  pageSize = 100,
  size = 'md',
  markerRow,
  page: pageInput,
  onChangePage,
  emptyMessage = 'No data',
  horizontalCenter,
  sorting,
  onSortingChange,
  ...stackProps
}: TableProps<T>) => {
  const [internalSorting, setInternalSorting] = useState<SortingState>([])

  const handleSortingChange: OnChangeFn<SortingState> = (updaterOrValue) => {
    const newSorting =
      typeof updaterOrValue === 'function'
        ? updaterOrValue(sorting ?? internalSorting)
        : updaterOrValue
    if (onSortingChange) {
      onSortingChange(newSorting)
    } else {
      setInternalSorting(newSorting)
    }
  }

  const table = useReactTable({
    columns: columns as unknown as ColumnDef<T, any>[],
    data,
    state: {
      sorting: sorting ?? internalSorting,
    },
    onSortingChange: handleSortingChange,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
  })

  const headerGroups = table.getHeaderGroups()
  const rows = table.getRowModel().rows

  const numPages = Math.ceil(rows.length / pageSize)
  const [_page, _setPage] = useState(cleanPage(pageInput ?? 0, numPages))
  const page = cleanPage(_page, numPages)
  const setPage = useCallback(
    (page: number) => _setPage(cleanPage(page, numPages)),
    [numPages, _setPage]
  )

  useEffect(() => {
    if (pageInput) {
      setPage(pageInput)
    }
  }, [pageInput])

  const pagedRows = useMemo(() => {
    if (!pageSize) {
      return rows
    } else {
      const start = page * pageSize
      return rows.slice(start, start + pageSize)
    }
  }, [rows, page, pageSize])

  const pagedWithCustomRows: (Row<T> | TableMarkerRow)[] = useMemo(() => {
    if (markerRow) {
      const pagedWithCustomRows = [...pagedRows] as (Row<T> | TableMarkerRow)[]
      if (markerRow.insertIndex >= 0 && markerRow.insertIndex <= pagedRows.length) {
        pagedWithCustomRows.splice(markerRow.insertIndex, 0, markerRow)
      }
      return pagedWithCustomRows
    } else {
      return pagedRows
    }
  }, [pagedRows, markerRow])

  const isNonEmpty = pagedWithCustomRows.length > 0

  const tableRef = useRef<HTMLElement>(null)
  const markerRowRef = useRef<HTMLElement>(null)

  // Auto-center table vertically around marker row
  useEffect(() => {
    if (tableRef.current && markerRowRef.current && markerRow?.autoCenter) {
      // Center horizontally on mount
      const scrollableDiv = tableRef.current

      // Center vertically around spot price marker
      const spotPriceMarker = markerRowRef.current

      const scrollableDivVisibleHeight = scrollableDiv.offsetHeight
      const rowTopPosition = spotPriceMarker.offsetTop

      // Calculate the scrollTop value to center the row
      const scrollTop = rowTopPosition - scrollableDivVisibleHeight / 2

      // Center vertically around spot price marker
      scrollableDiv.scrollTop = scrollTop
    }
  }, [])

  // Auto-center table horizontally
  useEffect(() => {
    if (horizontalCenter && tableRef.current) {
      // Center horizontally on mount
      const scrollableDiv = tableRef.current
      const scrollWidth = scrollableDiv.scrollWidth
      const clientWidth = scrollableDiv.clientWidth
      const scrollLeft = (scrollWidth - clientWidth) / 2
      scrollableDiv.scrollLeft = scrollLeft
    }
  }, [])

  return (
    <YStack {...stackProps} ref={tableRef} style={{ overflow: 'auto' }}>
      {/* grows to parent, allows for footer space */}
      <YStack
        flexGrow={1}
        flexShrink={1}
        width="100%"
        style={{ borderCollapse: 'collapse', display: 'table' }}
        paddingHorizontal="$1"
      >
        <YStack zIndex="$tableHeader" style={{ position: 'sticky', top: 0 }}>
          {filterNulls(
            headerGroups.map((headerGroup, idx) => {
              const tableSorting = table.getState().sorting
              return (
                <XStack key={headerGroup.id} backgroundColor="$appBg">
                  {headerGroup.headers.map((header) => {
                    const isSortable = header.column.columnDef.meta?.isSortable
                    const { maxWidth, minWidth, justifyContent, showLeftBorder, showRightBorder } =
                      header.column.columnDef.meta ?? {}

                    const paddingProps = getHeaderPaddingProps(header.getContext(), size)
                    const widthProps = getCellWidthProps({ minWidth, maxWidth }, paddingProps)
                    const styleProps: StackProps = mergeDeep(paddingProps, widthProps)

                    const isSorted = tableSorting.find((sort) => sort.id === header.column.id)
                    const sortDirection =
                      isSorted?.desc === undefined ? null : isSorted.desc ? 'desc' : 'asc'

                    return (
                      <XStack
                        key={header.id}
                        margin={0}
                        borderColor="$hairline"
                        borderLeftWidth={showLeftBorder ? '1px' : undefined}
                        borderRightWidth={showRightBorder ? '1px' : undefined}
                        borderBottomWidth={idx === headerGroups.length - 1 ? '1px' : 0}
                        borderTopWidth={'1px'}
                        {...styleProps}
                        justifyContent={justifyContent}
                      >
                        {isSortable ? (
                          <XStack
                            onPress={() => {
                              const newSorting = (() => {
                                const existingSort = tableSorting.find(
                                  (s) => s.id === header.column.id
                                )
                                if (!existingSort) {
                                  return [{ id: header.column.id, desc: true }]
                                } else if (existingSort.desc) {
                                  return [{ id: header.column.id, desc: false }]
                                } else {
                                  return []
                                }
                              })()
                              table.setSorting(newSorting)
                            }}
                            userSelect="none"
                            alignItems="center"
                            gap="$1"
                            cursor="pointer"
                          >
                            <LabelText
                              cursor="pointer"
                              color={isSorted ? 'primary' : undefined}
                              hoverStyle={{ color: '$primaryText' }}
                            >
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </LabelText>
                            {sortDirection === 'asc' ? (
                              <Icon icon={<ArrowUp />} size={12} />
                            ) : sortDirection === 'desc' ? (
                              <Icon icon={<ArrowDown />} size={12} />
                            ) : null}
                          </XStack>
                        ) : (
                          <LabelText>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </LabelText>
                        )}
                      </XStack>
                    )
                  })}
                </XStack>
              )
            })
          )}
        </YStack>
        {pagedWithCustomRows.length > 0 ? (
          pagedWithCustomRows.map((row) => {
            if (
              'content' in row &&
              (isValidElement(row.content) ||
                typeof row.content === 'string' ||
                typeof row.content === 'number')
            ) {
              return (
                <XStack key={row.id} ref={markerRowRef}>
                  {row.content}
                </XStack>
              )
            } else {
              return (
                <TableBodyRow
                  key={(row as Row<TableData<T>>).id}
                  row={row as Row<TableData<T>>}
                  size={size}
                />
              )
            }
          })
        ) : (
          <EmptyTableBodyRow
            key="empty"
            size={size}
            emptyMessage={
              typeof emptyMessage === 'string' || typeof emptyMessage === 'number' ? (
                <BodyText color="secondary">{emptyMessage}</BodyText>
              ) : (
                emptyMessage
              )
            }
          />
        )}
      </YStack>
      {isNonEmpty && numPages > 1 ? (
        <XStack
          backgroundColor="$appBg"
          paddingHorizontal="$3"
          style={{ position: 'sticky', bottom: 0 }}
        >
          <XStack
            width="100%"
            justifyContent="center"
            alignItems="center"
            paddingVertical="$1.5"
            borderTopColor="$hairline"
            borderTopWidth={1}
            $mobile={{ justifyContent: 'flex-start' }}
            gap="$2"
          >
            <Button
              icon={<ChevronsLeft />}
              isDisabled={page === 0}
              onPress={() => {
                setPage(0)
                if (onChangePage) {
                  onChangePage(0)
                }
              }}
            />
            <Button
              icon={<ChevronLeft />}
              isDisabled={page === 0}
              onPress={() => {
                const newPage = cleanPage(page - 1, numPages)
                setPage(newPage)
                if (onChangePage) {
                  onChangePage(newPage)
                }
              }}
            />
            <YStack justifyContent="center">
              <BodyText>
                {page + 1} / {Math.max(1, numPages)}
              </BodyText>
            </YStack>
            <Button
              icon={<ChevronRight />}
              isDisabled={page === numPages - 1}
              onPress={() => {
                const newPage = cleanPage(page + 1, numPages)
                setPage(newPage)
                if (onChangePage) {
                  onChangePage(newPage)
                }
              }}
            />
            <Button
              icon={<ChevronsRight />}
              isDisabled={page === numPages - 1}
              onPress={() => {
                setPage(numPages - 1)
                if (onChangePage) {
                  onChangePage(numPages - 1)
                }
              }}
            />
          </XStack>
        </XStack>
      ) : null}
    </YStack>
  )
}

export default Table
