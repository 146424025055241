import useSubaccount from '@lyra/web/hooks/useSubaccount'
import useYieldPositions from '@lyra/web/hooks/useYieldPositions'
import { useMemo } from 'react'

import useLyraFundingBalances from './useLyraFundingBalances'

export default function useTotalAccountValue() {
  const { data: yieldPositions, isLoading, error } = useYieldPositions()
  const { subaccounts } = useSubaccount()
  const { fundingBalances } = useLyraFundingBalances()

  const tradingAccountValue = useMemo(() => {
    return Object.values(subaccounts).reduce((sum, sub) => {
      return sum + +sub.subaccount_value
    }, 0)
  }, [subaccounts])

  const fundingAccountValue = useMemo(() => {
    return Object.values(fundingBalances).reduce((sum, bal) => sum + bal.value, 0)
  }, [fundingBalances])

  const yieldValue = useMemo(() => {
    if (!yieldPositions) {
      return 0
    }
    return Object.values(yieldPositions).reduce((sum, pos) => sum + pos.value, 0)
  }, [yieldPositions])

  return useMemo(
    () => ({
      data:
        !isLoading && !error
          ? {
              tradingAccountValue,
              fundingAccountValue,
              yieldValue,
              totalValue: tradingAccountValue + fundingAccountValue + yieldValue,
            }
          : undefined,
      isLoading,
      error,
    }),
    [tradingAccountValue, fundingAccountValue, yieldValue, isLoading, error]
  )
}
