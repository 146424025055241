import Button from '@lyra/core/components/Button'
import { ArrowLeftRight } from '@lyra/core/components/Icon'
import LabelText from '@lyra/core/components/LabelText'
import Section from '@lyra/core/components/Section'
import { WEI_DECIMALS } from '@lyra/core/constants/contracts'
import { bigNumberToString } from '@lyra/core/utils/bigNumberToString'
import filterNulls from '@lyra/core/utils/filterNulls'
import { CollateralId, TokenId } from '@lyra/web/constants/tokens'
import { TransactionOptions } from '@lyra/web/constants/transactions'
import useLyraFundingBalances from '@lyra/web/hooks/useLyraFundingBalances'
import useSubaccount from '@lyra/web/hooks/useSubaccount'
import useTransaction from '@lyra/web/hooks/useTransaction'
import {
  getSubaccountBorrowAmount,
  getTransferInput,
  getTransferLoggerAction,
} from '@lyra/web/utils/subaccounts'
import { formatTokenSymbol, getCollateralForToken } from '@lyra/web/utils/tokens'
import { useCallback, useMemo, useState } from 'react'
import { XStack } from 'tamagui'

import WalletTransactionModal from '../WalletTransactionModal'
import TransferAccountDropdown, { TransferSubaccountId } from './TransferAccountDropdown'
import TransferDepositRows from './TransferDepositRows'
import TransferRows from './TransferRows'
import TransferWithdrawRows from './TransferWithdrawRows'

export type TransferModalProps = {
  defaultFromSubaccountId?: TransferSubaccountId | null
  fromSubaccountId?: TransferSubaccountId | null
  defaultToSubaccountId?: TransferSubaccountId | null
  toSubaccountId?: TransferSubaccountId | null
  defaultAsset?: TokenId | null
  asset?: TokenId | null
  useCase?: 'transfer' | 'supply' | 'borrow'
}

type Props = {
  onClose: () => void
} & TransferModalProps

/**
 * In the TransferModal, we refer to:
 * Deposits: Transfer from Funding Wallet -> Subaccount
 * Withdraw: Transfer from Subaccount -> Funding Wallet
 * Transfer: Transfer from Subaccount -> Subaccount
 * Create: Transfer from Funding -> New Subaccount (only applicable for an account with no subaccounts)
 */
export default function TransferModal({
  defaultFromSubaccountId: defaultFromSubaccountIdInput,
  fromSubaccountId: fromSubaccountIdInput,
  defaultToSubaccountId: defaultToSubaccountIdInput,
  toSubaccountId: toSubaccountIdInput,
  defaultAsset,
  asset,
  useCase = 'transfer',
  onClose,
}: Props) {
  const { createAndDepositFirstSubaccount } = useTransaction()
  const { subaccounts, subaccountDatas, deposit, transfer, withdraw } = useSubaccount()
  const { fundingBalances } = useLyraFundingBalances()

  const defaultToSubaccountId: TransferSubaccountId | 'create' = useMemo(() => {
    if (!Object.values(subaccountDatas).length) {
      // IMPORTANT!! only trigger 'create' state when user has no subaccounts
      // create first subaccount
      return 'create'
    }

    if (
      toSubaccountIdInput &&
      ((typeof toSubaccountIdInput === 'number' && !!subaccountDatas[toSubaccountIdInput]) ||
        toSubaccountIdInput === 'funding')
    ) {
      return toSubaccountIdInput
    }

    if (
      defaultToSubaccountIdInput &&
      ((typeof defaultToSubaccountIdInput === 'number' &&
        !!subaccountDatas[defaultToSubaccountIdInput]) ||
        defaultToSubaccountIdInput === 'funding')
    ) {
      return defaultToSubaccountIdInput
    }

    // default to subaccount with most value
    const sortedSubaccountIds = Object.values(subaccountDatas)
      .sort((a, b) => b.subaccountValue - a.subaccountValue)
      .map((sub) => sub.subaccount.subaccount_id)
    return sortedSubaccountIds[0]
  }, [defaultToSubaccountIdInput, toSubaccountIdInput, subaccountDatas])

  const [selectedTokenId, setSelectedTokenId] = useState<TokenId>(
    asset ? asset : defaultAsset ? defaultAsset : TokenId.USDC
  )

  const defaultFromSubaccountId = useMemo(() => {
    if (
      fromSubaccountIdInput &&
      ((typeof fromSubaccountIdInput === 'number' && !!subaccountDatas[fromSubaccountIdInput]) ||
        fromSubaccountIdInput === 'funding')
    ) {
      return fromSubaccountIdInput
    }

    if (
      defaultFromSubaccountIdInput &&
      ((typeof defaultFromSubaccountIdInput === 'number' &&
        !!subaccountDatas[defaultFromSubaccountIdInput]) ||
        defaultFromSubaccountIdInput === 'funding')
    ) {
      return defaultFromSubaccountIdInput
    }

    const collateralId = getCollateralForToken(selectedTokenId)

    // default to subaccount or funding with the most available balance of the selected token
    const tokenBalances: { id: TransferSubaccountId; balance: number }[] = Object.values(
      subaccountDatas
    ).map((sub) => ({
      id: sub.subaccount.subaccount_id,
      balance: sub.collateralBalances[collateralId].withdrawableBalance,
    }))

    tokenBalances.push({
      id: 'funding',
      balance: fundingBalances[selectedTokenId].balance,
    })

    const filteredTokenBalances = tokenBalances
      .filter((b) => b.balance > 0 && b.id !== defaultToSubaccountId)
      .sort((a, b) => b.balance - a.balance)

    return filteredTokenBalances.length ? filteredTokenBalances[0].id : 'funding'
  }, [
    fromSubaccountIdInput,
    defaultFromSubaccountIdInput,
    selectedTokenId,
    subaccountDatas,
    fundingBalances,
    defaultToSubaccountId,
  ])

  const [_fromSubaccountId, setFromSubaccountId] =
    useState<TransferSubaccountId>(defaultFromSubaccountId)

  const [_toSubaccountId, setToSubaccountId] = useState<TransferSubaccountId | 'create'>(
    defaultToSubaccountId
  )

  const isTokenLocked = !!asset
  const isToSubaccountIdLocked = !!toSubaccountIdInput
  const isFromSubaccountIdLocked = !!fromSubaccountIdInput

  const [amountInput, setAmountInput] = useState<bigint>(BigInt(0))

  // Typed inputs
  const {
    type: transferType,
    fromSubaccountId,
    toSubaccountId,
  } = getTransferInput(_fromSubaccountId, _toSubaccountId)
  const toSubaccount =
    toSubaccountId && toSubaccountId !== 'funding' ? subaccounts[toSubaccountId] : null
  const selectedCollateral = getCollateralForToken(selectedTokenId)

  const isNonUsdcTransferToPm =
    !!toSubaccount && toSubaccount.margin_type === 'PM' && selectedTokenId !== TokenId.USDC

  const handleSelectFromSubaccountId = useCallback((subaccountId: TransferSubaccountId) => {
    setFromSubaccountId(subaccountId)
    setAmountInput(BigInt(0))
  }, [])

  const handleSelectToSubaccountId = useCallback((subaccountId: TransferSubaccountId) => {
    setToSubaccountId(subaccountId)
    setAmountInput(BigInt(0))
  }, [])

  const handleChangeTokenId = useCallback((tokenId: TokenId) => {
    setAmountInput(BigInt(0))
    setSelectedTokenId(tokenId)
  }, [])

  const wrappedTransfer = useCallback(
    async (options: TransactionOptions) => {
      const collateral = getCollateralForToken(selectedTokenId)
      if (transferType !== 'transfer') {
        throw new Error(`Wrong transfer function, "transfer" called but "${transferType}" detected`)
      }
      return transfer(
        fromSubaccountId,
        toSubaccountId,
        collateral,
        bigNumberToString(amountInput, WEI_DECIMALS),
        options
      )
    },
    [amountInput, fromSubaccountId, selectedTokenId, toSubaccountId, transfer, transferType]
  )

  const wrappedDeposit = useCallback(
    (options: TransactionOptions) => {
      if (transferType !== 'deposit') {
        throw new Error(`Wrong transfer function, "deposit" called but "${transferType}" detected`)
      }
      return deposit(toSubaccountId, selectedTokenId, amountInput, options)
    },
    [amountInput, deposit, selectedTokenId, toSubaccountId, transferType]
  )

  const wrappedWithdraw = useCallback(
    (options: TransactionOptions) => {
      if (transferType !== 'withdraw') {
        throw new Error(`Wrong transfer function, "withdraw" called but "${transferType}" detected`)
      }
      return withdraw(fromSubaccountId, selectedCollateral, amountInput, options)
    },
    [amountInput, fromSubaccountId, selectedCollateral, transferType, withdraw]
  )

  const wrappedCreate = useCallback(
    (options: TransactionOptions) => {
      if (transferType !== 'create') {
        throw new Error(`Wrong transfer function, "create" called but "${transferType}" detected`)
      }
      if (Object.values(subaccounts).length) {
        throw new Error('Initial subaccount already created')
      }
      return createAndDepositFirstSubaccount({
        ...options,
        firstSubaccountParams: {
          marginType: 'SM',
          amount: amountInput,
          token: selectedTokenId,
        },
      })
    },
    [amountInput, createAndDepositFirstSubaccount, selectedTokenId, subaccounts, transferType]
  )

  const isEmptyAmount = amountInput === BigInt(0)

  const balance =
    transferType === 'transfer'
      ? // Free (unlocked) collateral balance for transfer
        subaccountDatas[fromSubaccountId].collateralBalances[selectedCollateral]
          .transferrableBalanceBn
      : transferType === 'withdraw'
      ? // Free (unlocked) collateral balance for withdraw
        subaccountDatas[fromSubaccountId].collateralBalances[selectedCollateral]
          .withdrawableBalanceBn
      : // Funding wallet balance for deposit/create
      transferType === 'deposit' || transferType === 'create'
      ? fundingBalances[selectedTokenId].balanceBn
      : BigInt(0)

  const isInsufficientBalance = amountInput > balance

  const isBorrowing =
    (transferType === 'transfer' || transferType === 'withdraw') &&
    selectedCollateral === CollateralId.USDC &&
    getSubaccountBorrowAmount(subaccountDatas[fromSubaccountId], amountInput, transferType)

  const ignoreFromSubaccountIds = useMemo(() => {
    return filterNulls([
      toSubaccountId,
      // do not enable transfers from subaccounts with no collateral
      ...Object.values(subaccountDatas)
        .filter((sub) => !sub.collateralValue)
        .map((sub) => sub.subaccount.subaccount_id),
    ])
  }, [toSubaccountId, subaccountDatas])

  const buttonLabel = `${isBorrowing ? 'Borrow' : 'Transfer'}${
    isTokenLocked ? ` ${formatTokenSymbol(selectedTokenId)}` : ''
  }`

  const title = `${
    useCase === 'borrow' ? 'Borrow' : useCase === 'transfer' ? 'Transfer' : 'Supply'
  }${isTokenLocked ? ` ${formatTokenSymbol(selectedTokenId)}` : ''}`

  return (
    <WalletTransactionModal
      reviewTitle={title}
      loggerAction={getTransferLoggerAction(transferType)}
      onClose={onClose}
      transaction={
        transferType === 'transfer'
          ? wrappedTransfer
          : transferType === 'withdraw'
          ? wrappedWithdraw
          : transferType === 'create'
          ? wrappedCreate
          : wrappedDeposit
      }
      reviewContent={
        <>
          {!isFromSubaccountIdLocked ? (
            <Section.YStack>
              <LabelText color="secondary">From</LabelText>
              <TransferAccountDropdown
                strategy="fixed"
                selectedSubaccountId={fromSubaccountId}
                onSelect={handleSelectFromSubaccountId}
                ignoreSubaccountIds={ignoreFromSubaccountIds}
              />
            </Section.YStack>
          ) : null}
          {!isToSubaccountIdLocked ? (
            <Section.YStack>
              <LabelText color="secondary">To</LabelText>
              <XStack gap="$2">
                <TransferAccountDropdown
                  strategy="fixed"
                  selectedSubaccountId={toSubaccountId}
                  onSelect={handleSelectToSubaccountId}
                  ignoreSubaccountIds={[fromSubaccountId]}
                />
                {transferType !== 'create' &&
                !isFromSubaccountIdLocked &&
                !isToSubaccountIdLocked ? (
                  <Button
                    icon={<ArrowLeftRight />}
                    size="lg"
                    onPress={() => {
                      const tmp = fromSubaccountId
                      handleSelectFromSubaccountId(toSubaccountId)
                      handleSelectToSubaccountId(tmp)
                    }}
                  />
                ) : null}
              </XStack>
            </Section.YStack>
          ) : null}
          {transferType === 'transfer' ? (
            <TransferRows
              amount={amountInput}
              fromSubaccountId={fromSubaccountId}
              selectedTokenId={selectedTokenId}
              onChangeAmount={setAmountInput}
              onChangeToken={handleChangeTokenId}
              isTokenLocked={isTokenLocked}
            />
          ) : transferType === 'withdraw' ? (
            <TransferWithdrawRows
              amount={amountInput}
              selectedTokenId={selectedTokenId}
              fromSubaccountId={fromSubaccountId}
              onChangeAmount={setAmountInput}
              onChangeToken={handleChangeTokenId}
              isTokenLocked={isTokenLocked}
            />
          ) : (
            <TransferDepositRows
              amount={amountInput}
              selectedTokenId={selectedTokenId}
              onChangeAmount={setAmountInput}
              onChangeToken={handleChangeTokenId}
              isTokenLocked={isTokenLocked}
            />
          )}
        </>
      }
      isDisabled={isEmptyAmount || isInsufficientBalance || isNonUsdcTransferToPm}
      notice={
        isNonUsdcTransferToPm
          ? {
              status: 'warning',
              message: 'Portfolio margin accounts can only hold USDC collateral.',
            }
          : undefined
      }
      reviewButtonLabel={
        isNonUsdcTransferToPm
          ? 'USDC Only'
          : isEmptyAmount
          ? 'Enter Amount'
          : isInsufficientBalance
          ? 'Insufficient Balance'
          : buttonLabel
      }
    />
  )
}
