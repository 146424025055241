import DropdownButton, { DropdownButtonProps } from '@lyra/core/components/DropdownButton'
import { DEFAULT_CREATE_SUBACCOUNT_PARAMS, SubaccountData } from '@lyra/web/constants/subaccount'
import useSubaccount from '@lyra/web/hooks/useSubaccount'
import React from 'react'

import SubaccountsDropdownItems from '../SubaccountsDropdownItems'

export type TransferSubaccountId = number | 'funding'

type Props = {
  selectedSubaccountId?: TransferSubaccountId | null
  onSelect: (subaccountId: TransferSubaccountId) => void
  ignoreSubaccountIds?: TransferSubaccountId[]
} & Omit<DropdownButtonProps, 'children'>

const TransferAccountDropdown = ({
  selectedSubaccountId: selectedSubaccountId,
  ignoreSubaccountIds,
  onSelect,
  ...buttonProps
}: Props) => {
  const { subaccountDatas } = useSubaccount()
  const selectedSubaccountData: SubaccountData | null =
    selectedSubaccountId && selectedSubaccountId !== 'funding'
      ? subaccountDatas[selectedSubaccountId]
      : null

  return (
    <>
      <DropdownButton
        textAlign="left"
        size="lg"
        label={
          selectedSubaccountData
            ? selectedSubaccountData.label
            : selectedSubaccountId === 'funding'
            ? 'Funding Account'
            : DEFAULT_CREATE_SUBACCOUNT_PARAMS.label
        }
        dropdownProps={{
          maxHeight: 'min(400px, 70svh)',
        }}
        flexGrow={1}
        {...buttonProps}
      >
        {!ignoreSubaccountIds || !ignoreSubaccountIds.includes('funding') ? (
          <DropdownButton.ListItem
            size="lg"
            label="Funding Account"
            isSelected={selectedSubaccountId === 'funding'}
            onPress={() => onSelect('funding')}
          />
        ) : null}
        <SubaccountsDropdownItems
          size="lg"
          subaccountId={selectedSubaccountId !== 'funding' ? selectedSubaccountId : undefined}
          onSelect={(subaccountId) => {
            onSelect(subaccountId)
          }}
          ignoreSubaccountIds={ignoreSubaccountIds?.filter((i) => i !== 'funding') as number[]}
        />
      </DropdownButton>
    </>
  )
}

export default TransferAccountDropdown
