import DropdownButton, { DropdownButtonProps } from '@lyra/core/components/DropdownButton'
import formatUSD from '@lyra/core/utils/formatUSD'
import TokenIcon from '@lyra/web/components/common/TokenIcon'
import { CollateralId } from '@lyra/web/constants/tokens'
import { formatCollateralBalance, formatTokenSymbol } from '@lyra/web/utils/tokens'

type Props = {
  selectedCollateralId: CollateralId
  balances: { collateralId: CollateralId; balance: number; value: number }[]
  onChangeToken: (collateralId: CollateralId) => void
} & Omit<DropdownButtonProps, 'children'>

export default function TransferCollateralDropdown({
  balances,
  selectedCollateralId,
  onChangeToken,
  ...props
}: Props) {
  return (
    <DropdownButton
      textAlign="left"
      size="lg"
      label={balances.length ? formatTokenSymbol(selectedCollateralId) : 'No Balances'}
      leftContent={balances.length ? <TokenIcon size={18} tokenId={selectedCollateralId} /> : null}
      isDisabled={!balances.length}
      {...props}
    >
      {balances.map(({ collateralId, balance, value }) => (
        <DropdownButton.ListItem
          size="lg"
          icon={<TokenIcon size={18} tokenId={collateralId} />}
          key={collateralId}
          label={formatTokenSymbol(collateralId)}
          rightLabel={formatUSD(value)}
          isSelected={selectedCollateralId === collateralId}
          sublabel={formatCollateralBalance(balance, collateralId)}
          onPress={() => onChangeToken(collateralId)}
        />
      ))}
    </DropdownButton>
  )
}
