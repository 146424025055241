import BodyText from '@lyra/core/components/BodyText'
import LabelText from '@lyra/core/components/LabelText'
import Section from '@lyra/core/components/Section'
import formatPercentage from '@lyra/core/utils/formatPercentage'
import { CollateralId } from '@lyra/web/constants/tokens'
import useCollateralData from '@lyra/web/hooks/useCollateralData'
import useSubaccount from '@lyra/web/hooks/useSubaccount'
import { formatCollateralBalance } from '@lyra/web/utils/tokens'
import { XStack } from 'tamagui'

type Props = {
  borrowAmount: number
  collateralId: CollateralId
  fromSubaccountId: number
}

export default function TransferWithdrawReceipt({
  borrowAmount,
  collateralId,
  fromSubaccountId,
}: Props) {
  const { subaccountDatas } = useSubaccount()
  const { collateralBalances, usdcAvailableToBorrow } = subaccountDatas[fromSubaccountId]

  // Always in 18dp
  const { balance, withdrawableBalance, lockedBalance } = collateralBalances[collateralId]

  const { data, isLoading } = useCollateralData(CollateralId.USDC)

  if (collateralId === CollateralId.USDC) {
    return (
      <Section.YStack>
        <XStack justifyContent="space-between" alignItems="center">
          <LabelText>Balance</LabelText>
          <BodyText>{formatCollateralBalance(balance, collateralId)}</BodyText>
        </XStack>
        <XStack justifyContent="space-between" alignItems="center">
          <LabelText>Available To Borrow</LabelText>
          <BodyText>{formatCollateralBalance(usdcAvailableToBorrow, collateralId)}</BodyText>
        </XStack>
        {borrowAmount > 0 ? (
          <>
            <XStack justifyContent="space-between" alignItems="center">
              <LabelText>Borrowing</LabelText>
              <BodyText>{formatCollateralBalance(borrowAmount, collateralId)}</BodyText>
            </XStack>
            <XStack justifyContent="space-between" alignItems="center">
              <LabelText>Borrow APY</LabelText>
              <BodyText>{isLoading || !data ? '...' : formatPercentage(+data.borrow_apy)}</BodyText>
            </XStack>
          </>
        ) : null}
      </Section.YStack>
    )
  } else {
    return (
      <Section.YStack>
        {lockedBalance > 0 ? (
          <>
            <XStack justifyContent="space-between" alignItems="center">
              <LabelText>Total Balance</LabelText>
              <BodyText>{formatCollateralBalance(balance, collateralId)}</BodyText>
            </XStack>
            <XStack justifyContent="space-between" alignItems="center">
              <LabelText>Locked Balance</LabelText>
              <BodyText>{formatCollateralBalance(lockedBalance, collateralId)}</BodyText>
            </XStack>
          </>
        ) : null}
        <XStack justifyContent="space-between" alignItems="center">
          <LabelText>{lockedBalance > 0 ? 'Available Balance' : 'Balance'}</LabelText>
          <BodyText>{formatCollateralBalance(withdrawableBalance, collateralId)}</BodyText>
        </XStack>
      </Section.YStack>
    )
  }
}
