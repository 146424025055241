import DropdownButton, { DropdownButtonProps } from '@lyra/core/components/DropdownButton'
import formatUSD from '@lyra/core/utils/formatUSD'
import TokenIcon from '@lyra/web/components/common/TokenIcon'
import { TokenId } from '@lyra/web/constants/tokens'
import { formatTokenBalance, formatTokenSymbol } from '@lyra/web/utils/tokens'

type Props = {
  selectedTokenId: TokenId
  balances: { tokenId: TokenId; balance: bigint; value: number }[]
  onChangeToken: (tokenId: TokenId) => void
} & Omit<DropdownButtonProps, 'children'>

export default function TransferTokenDropdown({
  balances,
  selectedTokenId,
  onChangeToken,
  ...props
}: Props) {
  return (
    <DropdownButton
      textAlign="left"
      size="lg"
      label={balances.length ? formatTokenSymbol(selectedTokenId) : 'No Balances'}
      leftContent={balances.length ? <TokenIcon size={18} tokenId={selectedTokenId} /> : null}
      isDisabled={!balances.length}
      {...props}
    >
      {balances.map(({ tokenId, balance, value }) => {
        return (
          <DropdownButton.ListItem
            size="lg"
            icon={<TokenIcon size={18} tokenId={tokenId} />}
            key={tokenId}
            label={formatTokenSymbol(tokenId)}
            rightLabel={formatUSD(value)}
            isSelected={selectedTokenId === tokenId}
            sublabel={formatTokenBalance(balance, tokenId)}
            onPress={() => onChangeToken(tokenId)}
          />
        )
      })}
    </DropdownButton>
  )
}
