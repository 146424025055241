import fetchCurrency from '@lyra/core/api/fetchCurrency'
import useSWR from 'swr'

import { CollateralId } from '../constants/tokens'

const fetcher = async (collateralId: CollateralId) => {
  return (await fetchCurrency({ currency: collateralId })).result
}

export default function useCollateralData(collateralId: CollateralId) {
  return useSWR(['CollateralData', collateralId], () => fetcher(collateralId), {
    revalidateOnFocus: false,
  })
}
