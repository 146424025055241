import BodyText from '@lyra/core/components/BodyText'
import Button from '@lyra/core/components/Button'
import BigNumberInput from '@lyra/core/components/Input/BigNumberInput'
import LabelText from '@lyra/core/components/LabelText'
import Section from '@lyra/core/components/Section'
import { TokenId } from '@lyra/web/constants/tokens'
import useLyraFundingBalances from '@lyra/web/hooks/useLyraFundingBalances'
import { formatTokenBalance, getTokenDecimals } from '@lyra/web/utils/tokens'
import { useEffect, useMemo } from 'react'
import { XStack } from 'tamagui'

import TransferTokenDropdown from './TransferTokenDropdown'

type Props = {
  amount: bigint
  selectedTokenId: TokenId
  onChangeToken: (token: TokenId) => void
  onChangeAmount: (amount: bigint) => void
  isTokenLocked?: boolean
}

/**
 * Handle Funding -> Subaccount deposits
 */
export default function TransferDepositRows({
  amount,
  selectedTokenId,
  onChangeToken,
  onChangeAmount,
  isTokenLocked,
}: Props) {
  const { fundingBalances } = useLyraFundingBalances()
  const balance = fundingBalances[selectedTokenId].balanceBn
  const decimals = getTokenDecimals(selectedTokenId)

  const sortedAndFilteredTokenBalances = useMemo(() => {
    return Object.entries(fundingBalances)
      .map(([tokenId, { balanceBn: balance, value }]) => ({
        tokenId: tokenId as TokenId,
        balance,
        value,
      }))
      .filter(({ balance }) => balance > BigInt(0))
      .sort((a, b) => b.value - a.value)
  }, [fundingBalances])

  // handle forcing collateral to supported balance
  useEffect(() => {
    if (
      !sortedAndFilteredTokenBalances.some((token) => token.tokenId === selectedTokenId) &&
      !isTokenLocked
    ) {
      const firstTokenId: TokenId | undefined = sortedAndFilteredTokenBalances[0]?.tokenId
      if (firstTokenId && selectedTokenId !== firstTokenId) {
        onChangeToken(firstTokenId)
        onChangeAmount(BigInt(0))
      }
    }
  }, [
    selectedTokenId,
    sortedAndFilteredTokenBalances,
    onChangeToken,
    onChangeAmount,
    isTokenLocked,
  ])

  return (
    <>
      {!isTokenLocked ? (
        <Section.YStack>
          <LabelText>Collateral</LabelText>
          <TransferTokenDropdown
            strategy="fixed"
            selectedTokenId={selectedTokenId}
            balances={sortedAndFilteredTokenBalances}
            onChangeToken={onChangeToken}
          />
        </Section.YStack>
      ) : null}
      <Section.YStack>
        <BigNumberInput
          decimals={decimals}
          value={amount}
          formatValue={(val) => formatTokenBalance(val, selectedTokenId)}
          onChangeValue={onChangeAmount}
          size="lg"
          label="Amount"
          rightContent={
            balance >= BigInt(0) ? (
              <Button
                size="sm"
                label="Max"
                onPress={() => onChangeAmount(balance)}
                isSelected={amount !== BigInt(0) && amount === balance}
              />
            ) : null
          }
        />
      </Section.YStack>
      <Section.YStack>
        <XStack justifyContent="space-between" alignItems="center">
          <LabelText>Balance</LabelText>
          <BodyText>{formatTokenBalance(balance, selectedTokenId)}</BodyText>
        </XStack>
      </Section.YStack>
    </>
  )
}
