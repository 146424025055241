import Button from '@lyra/core/components/Button'
import BigNumberInput from '@lyra/core/components/Input/BigNumberInput'
import LabelText from '@lyra/core/components/LabelText'
import Section from '@lyra/core/components/Section'
import { WEI_DECIMALS } from '@lyra/core/constants/contracts'
import { CollateralId, TokenId } from '@lyra/web/constants/tokens'
import useCollaterals from '@lyra/web/hooks/useCollaterals'
import useSubaccount from '@lyra/web/hooks/useSubaccount'
import { getCanBorrowUsdc, getSubaccountBorrowAmount } from '@lyra/web/utils/subaccounts'
import {
  formatCollateralBalance,
  getCollateralForToken,
  getTokenForCollateral,
} from '@lyra/web/utils/tokens'
import { useEffect, useMemo } from 'react'

import TransferCollateralDropdown from './TransferCollateralDropdown'
import TransferWithdrawReceipt from './TransferWithdrawReceipt'

type Props = {
  amount: bigint
  fromSubaccountId: number
  selectedTokenId: TokenId
  onChangeToken: (token: TokenId) => void
  onChangeAmount: (amount: bigint) => void
  isTokenLocked?: boolean
}

/**
 * Handle Subaccount -> Subaccount transfers
 */
export default function TransferRows({
  amount,
  fromSubaccountId,
  selectedTokenId,
  onChangeAmount,
  onChangeToken,
  isTokenLocked,
}: Props) {
  const { subaccountDatas } = useSubaccount()

  const collateralBalances = subaccountDatas[fromSubaccountId].collateralBalances

  const selectedCollateralId = getCollateralForToken(selectedTokenId)

  const { transferrableBalanceBn, lockedBalance } = collateralBalances[selectedCollateralId]

  const collaterals = useCollaterals()

  const sortedAndFilteredCollateralBalances = useMemo(() => {
    return Object.entries(collateralBalances)
      .map(([collateralId, { transferrableBalance }]) => ({
        collateralId: collateralId as CollateralId,
        balance: transferrableBalance,
        value: transferrableBalance * collaterals[collateralId as CollateralId].spotPrice,
      }))
      .filter(({ balance }) => balance > 0)
      .sort((a, b) => b.value - a.value)
  }, [collateralBalances, collaterals])

  // handle forcing collateral to supported balance
  useEffect(() => {
    if (
      !sortedAndFilteredCollateralBalances.some(
        (token) => token.collateralId === getCollateralForToken(selectedTokenId)
      ) &&
      !isTokenLocked
    ) {
      const firstCollateralId: CollateralId | undefined =
        sortedAndFilteredCollateralBalances[0]?.collateralId
      const firstTokenId = firstCollateralId ? getTokenForCollateral(firstCollateralId) : null
      if (firstTokenId && firstTokenId !== selectedTokenId) {
        onChangeToken(firstTokenId)
        onChangeAmount(BigInt(0))
      }
    }
  }, [
    selectedTokenId,
    sortedAndFilteredCollateralBalances,
    onChangeToken,
    onChangeAmount,
    isTokenLocked,
  ])

  const borrowAmount =
    selectedTokenId === TokenId.USDC
      ? getSubaccountBorrowAmount(subaccountDatas[fromSubaccountId], amount, 'transfer')
      : 0

  const canBorrow =
    selectedTokenId === TokenId.USDC &&
    getCanBorrowUsdc(subaccountDatas[fromSubaccountId].subaccount)

  const maxBalanceBn =
    canBorrow || lockedBalance > 0
      ? // if balance is borrowed or locked, add 3% buffer to max amount to account for spot price movements
        (transferrableBalanceBn * BigInt(97)) / BigInt(100)
      : transferrableBalanceBn

  return (
    <>
      {!isTokenLocked ? (
        <Section.YStack>
          <LabelText>Collateral</LabelText>
          <TransferCollateralDropdown
            strategy="fixed"
            selectedCollateralId={selectedCollateralId}
            balances={sortedAndFilteredCollateralBalances}
            onChangeToken={(collateral) => onChangeToken(getTokenForCollateral(collateral))}
          />
        </Section.YStack>
      ) : null}
      <Section.YStack>
        <BigNumberInput
          decimals={WEI_DECIMALS}
          value={amount}
          formatValue={(val) => formatCollateralBalance(val, selectedCollateralId)}
          onChangeValue={onChangeAmount}
          size="lg"
          label="Amount"
          rightContent={
            transferrableBalanceBn >= BigInt(0) ? (
              <Button
                size="sm"
                label="Max"
                onPress={() => onChangeAmount(maxBalanceBn)}
                isSelected={amount !== BigInt(0) && amount >= maxBalanceBn}
              />
            ) : null
          }
        />
      </Section.YStack>
      <TransferWithdrawReceipt
        borrowAmount={borrowAmount}
        fromSubaccountId={fromSubaccountId}
        collateralId={selectedCollateralId}
      />
    </>
  )
}
