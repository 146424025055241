import { ButtonSize } from '@lyra/core/components/Button'
import DropdownButton from '@lyra/core/components/DropdownButton'
import filterNulls from '@lyra/core/utils/filterNulls'
import formatTruncatedUSD from '@lyra/core/utils/formatTruncatedUSD'
import { useMemo } from 'react'

import useSubaccount from '../../hooks/useSubaccount'

type Props = {
  subaccountId?: number | null
  onSelect: (subaccountId: number) => void
  isStandardMarginOnly?: boolean
  ignoreSubaccountIds?: number[]
  size?: ButtonSize
}

const SubaccountsDropdownItems = ({
  subaccountId: selectedSubaccountId,
  onSelect,
  isStandardMarginOnly,
  ignoreSubaccountIds,
  size,
}: Props) => {
  const { subaccountDatas } = useSubaccount()

  const sortedAndFilteredSubaccounts = useMemo(() => {
    return filterNulls(
      Object.values(subaccountDatas).map((subaccount) => {
        if (
          ignoreSubaccountIds &&
          ignoreSubaccountIds.includes(subaccount.subaccount.subaccount_id)
        ) {
          return null
        }

        return subaccount
      })
    ).sort((a, b) => b.subaccountValue - a.subaccountValue)
  }, [subaccountDatas, ignoreSubaccountIds])

  return (
    <>
      {sortedAndFilteredSubaccounts.map(({ subaccount, label }) => {
        const isCurrentSubaccount = subaccount.subaccount_id === selectedSubaccountId

        const isPM = subaccount.margin_type === 'PM'
        const currency = subaccount.currency
        const value = `${formatTruncatedUSD(+subaccount.subaccount_value)}`
        return (
          <DropdownButton.ListItem
            key={subaccount.subaccount_id}
            label={label}
            sublabel={value}
            rightLabel={isPM ? 'Portfolio' : 'Standard'}
            rightSublabel={isPM ? `${currency} Market` : 'All Markets'}
            isSelected={isCurrentSubaccount}
            onPress={() => onSelect(subaccount.subaccount_id)}
            isDisabled={isStandardMarginOnly && subaccount.margin_type !== 'SM'}
            size={size}
          />
        )
      })}
    </>
  )
}

export default SubaccountsDropdownItems
