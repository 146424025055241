import TrackButton, { TrackButtonProps } from '@lyra/web/components/common/TrackButton'
import useLyraFundingBalances from '@lyra/web/hooks/useLyraFundingBalances'
import useNewAccount from '@lyra/web/hooks/useNewAccount'
import useSubaccount from '@lyra/web/hooks/useSubaccount'
import { getCollateralForToken } from '@lyra/web/utils/tokens'
import { useMemo, useState } from 'react'

import TransferModal, { TransferModalProps } from './TransferModal'

type Props = TransferModalProps & Omit<TrackButtonProps, 'onPress' | 'eventName'>

export default function TransferButton({
  defaultFromSubaccountId,
  fromSubaccountId,
  defaultToSubaccountId,
  toSubaccountId,
  defaultAsset,
  asset,
  isDisabled,
  useCase,
  label = 'Transfer',
  ...props
}: Props) {
  const [isOpen, setIsOpen] = useState(false)

  const { isAuthWithNoFirstDeposit } = useNewAccount()

  const { subaccountDatas } = useSubaccount()
  const { fundingBalances } = useLyraFundingBalances()

  const isAssetSpecifiedAndUnavailable = useMemo(() => {
    if (!asset) {
      // ignore if asset is not defined
      return false
    }

    const collateralId = getCollateralForToken(asset)
    const hasSubaccountBalance = Object.values(subaccountDatas).some(
      (sub) => sub.collateralBalances[collateralId].withdrawableBalance > 0
    )
    const hasFundingBalance = fundingBalances[asset].balance > 0

    return !hasSubaccountBalance && !hasFundingBalance
  }, [asset, subaccountDatas, fundingBalances])

  return (
    <>
      <TrackButton
        {...props}
        eventName="transfer"
        label={label}
        onPress={(e) => {
          e.stopPropagation()
          setIsOpen(true)
        }}
        // disable when user has no funds in funding account
        isDisabled={isDisabled || isAuthWithNoFirstDeposit || isAssetSpecifiedAndUnavailable}
      />
      {isOpen ? (
        <TransferModal
          defaultFromSubaccountId={defaultFromSubaccountId}
          fromSubaccountId={fromSubaccountId}
          defaultToSubaccountId={defaultToSubaccountId}
          toSubaccountId={toSubaccountId}
          defaultAsset={defaultAsset}
          asset={asset}
          useCase={useCase}
          onClose={() => setIsOpen(false)}
        />
      ) : null}
    </>
  )
}
